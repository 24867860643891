$spacer-break-one: 950px;

// SPACING
$gutter-one: 4.3rem;
$gutter-two: 2rem;
$gutter-three: 3.75rem;

// CONTAINER WIDTHS
$container-one: 120rem;
$container-two: 74.8rem;
$container-three: 60rem;

// FONTS
$resp-font-size: calc(12px + 0.4vw);
$font-size-one: 16px;
$font-size-two: 14px;
$line-height: 1.4;
$font-one: 'Raleway', sans-serif;

// COLOURS
$color-one: #2D717B;
$color-two: #DAE3E5;
$color-three: #fff;
$color-four: #707070;
$color-five: #2F6E84;
$color-six: #3D7DA7;
$color-seven: #2ABDD9;
$color-eight: #F82571;

// TRANSITIONS
$transition-one: ease 0.4s;
$transition-two: ease 0.2s;
$transition-three: ease 0.6s;
