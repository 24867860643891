.container {
	width: 100%;
	max-width: $container-one;
	margin: auto;
	padding-left: $gutter-two;
	padding-right: $gutter-two;

	@media (min-width: 700px) {
		padding-left: $gutter-one;
		padding-right: $gutter-one;
	}

	&--two {
		max-width: $container-two;
	}

	&--three {
		max-width: $container-three;
	}
}

