.announcement-banner {
	background-color: $color-eight;
	color: $color-three;
	text-align: center;
	padding: 0.5rem 1rem 0.6rem;

	p {
		margin: 0;
		max-width: unset;
		font-weight: 600;
	}
}
