.icon-list {
	&__item {
		a {
			@include font-preset--5;
			color: $color-one;
			display: flex;

			@media (max-width: 1600px) {
				font-size: 1rem !important;
			}

			@media (max-width: 420px) {
				font-size: 0.8rem !important;
			}
		}

		img {
			max-width: 2rem;
			margin-right: 1.25rem;
		}

		&--small {
			display: flex;
			align-items: center;

			img {
				max-width: 1.5rem;
				margin: 0 0 0 0.75rem;
			}
		}
	}
}
