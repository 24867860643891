.footer {
	background-image: url('/assets/img/pattern-tile.svg');
	padding: 4rem 0 10rem;

	&__container {
		display: flex;
		flex-wrap: wrap;
		background-color: $color-three;
		box-shadow: inset 0 0 0 1.5rem $color-three,
		inset 0 0 0 calc(1.5rem + 1px) $color-one;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		border-top: 1px solid $color-three;
	}

	&__item {
		width: 100%;
		padding: 3rem 1.5rem;
		text-align: center;

		@media (min-width: 1000px) {
			width: 33.33%;
			padding: 3rem 1.5rem;
		}

		&:not(&:first-of-type) {
			border-top: 1px solid $color-one;

			@media (min-width: 1000px) {
				border-top: unset;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 2rem;
					bottom: 2rem;
					width: 1px;
					background-color: $color-one;
				}
			}
		}
	}

	&__badges {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 1rem 0;
		border-top: 1px solid $color-one;

		@media (min-width: 600px) {
			flex-wrap: nowrap;
		}
	}

	&__badge {
		margin: 0 0.5rem 0.5rem;
	}

	&__heading {
		@include font-preset--6;
		margin-top: 0;
	}

	&__copy {
		@include font-preset--9;
		font-size: 1rem !important;

		a {
			color: inherit;
		}
	}

	.icon-list {
		&__item {
			justify-content: center;

			img {
				margin: 0 0.35rem 0;
			}
		}
	}
}

.privacy-link {
	text-align: center;
	width: 100%;
	padding: 1.5rem 1rem;

	a {
		color: inherit;
		text-decoration: none;
		font-weight: 600;
		text-transform: uppercase;
	}
}