$icon-col-break-one: 1000px;
$icon-col-break-two: 480px;

.icon-columns {
	text-align: center;
	margin-bottom: 5rem;

	+.icon-columns {
		margin-top: -3.5rem;
	}

	@media (min-width: $spacer-break-one) {
		margin-bottom: 8rem;
	}

	&--small {
		.icon-columns__item {
			width: calc(50% - 2.5rem);
			padding: 0 1rem;

			&::before,
			&::after {
				display: none;
			}

			@media (min-width: 620px) {
				width: calc(33.33% - 2.5rem);
			}

			@media (min-width: 1400px) {
				padding: 0 2.1rem;
				width: calc(16.66% - 2.5rem);
			}
		}

		.icon-columns__image {
			max-width: 100px;
		}
	}

	&__section-heading {
		@include font-preset--3;
		margin: 0;
		max-width: unset;
	}

	&__container {
		margin-top: 4.12rem;
	}

	&__cols {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -1.25rem !important;
	}

	&__item {
		width: calc(100% - 2.5rem);
		margin: 0 1.25rem 2.5rem;
		padding: 0 1.5rem;

		@media (min-width: $icon-col-break-one) {
			// &:not(&:first-of-type) {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		left: -1.25rem;
			// 		top: 0;
			// 		bottom: 0;
			// 		border-left: 3px dashed $color-two;
			// 	}
			// }
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: -1.25rem;
				top: 0;
				bottom: 0;
				border-left: 3px dashed $color-two;
			}

			&::after {
				left: unset;
				right: calc(-1.25rem - 3px);
			}
		}

		@media (min-width: 1150px) {
			padding: 0 3rem;
		}

		@media (min-width: $icon-col-break-two) {
			width: calc(50% - 2.5rem);
		}
		

		@media (min-width: $icon-col-break-one) {
			width: calc(25% - 2.5rem);
		}

		
	}

	&__image {
		max-width: 130px;
		margin: auto;
	}

	&__heading {
		@include font-preset--6;
	}

	&__copy {
		@include font-preset--8;
		font-weight: 700;
		max-width: unset;
		padding: 0 2.5rem;
	}
}
