.article-cards {
	margin-top: 2.5rem;

	&__container {}

	&__cols {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 -1rem;
	}

	&__item {
		width: calc(100% - 2rem);
		margin: 1.5rem 1rem;
		color: $color-one;
		text-decoration: none;

		@media (min-width: 475px) {
			width: calc(50% - 2rem);
		}

		@media (min-width: 800px) {
			width: calc(33.33% - 2rem);
		}

		@media (min-width: 1100px) {
			width: calc(25% - 2rem);
		}
	}

	&__image {
		aspect-ratio: 1;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		background-color: #e8e8e8;
	}

	&__heading {
		font-size: 1.25rem;
		margin-top: 1ex;

		@media (min-width: 1100px) {
			font-size: 1.5rem;
		}
	}

	&__date {
		margin-top: 0.5ex;
		font-size: 0.85rem;
	}

	&__copy {
		margin-top: 0.8ex;
		font-size: 1rem;
	}

	&__button {
		margin-top: 1.1ex;
		display: inline-block;
		font-size: 1rem;
	}
}
