.button {
	@include font-preset--6;
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.125rem;
	padding: 0.85rem 1.7rem 0.9rem;
	border-radius: 1rem;
	transition: all $transition-one;
	cursor: pointer;
	border: 1px solid $color-one;
	color: $color-three;
	background-color: $color-one;
	box-shadow: 0 5px 5px rgb(0 0 0 / 15%);

	&:hover {
		background-color: $color-three;
		color: $color-one;
	}

	&--reverse {
		background-color: $color-three;
		color: $color-one;

		&:hover {
			background-color: $color-one;
			color: $color-three;
		}
	}

	
}
