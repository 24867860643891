.contact-form {
	&__row {
		margin-top: 1rem;

		&--button {
			text-align: right;
		}
	}

	&__heading {
		position: absolute;
		font-size: 1rem;
	}

	&__button {}

	input {
		color: $color-one;
		width: 100%;
		border: 0;
		padding: 0.7rem;

		&[name="message"] {}
	}
}
