.team-info {
	margin-bottom: 5rem;

	@media (min-width: $spacer-break-one) {
		margin-bottom: 8rem;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
	}

	&__section-heading {
		@include font-preset--3;
		margin: 0 0 1.3ex;
		max-width: unset;
		width: 100%;
	}
	

	&__image {
		width: 100%;
		margin: 0 0 1.5rem;

		@media (min-width: 750px) {
			width: calc(33.33% - 1.8rem);
			margin: 0 1.8rem 0 0;
		}
	}

	&__text {
		width: 100%;
		margin: 0 0 1.5rem;

		@media (min-width: 750px) {
			width: calc(66.66% - 1.8rem);
			margin: 0 1.8rem 0 0;
		}
	}

	&__heading {
		@include font-preset--6;
		margin-top: 0;
	}

	&__subheading {
		@include font-preset--9;
		margin-top: 0;
	}

	&__copy {
		@include font-preset--9;
		font-size: 1rem !important;
	}
}


