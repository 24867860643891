$dotted-line-break-one: 950px;

.dotted-line {
	width: 7.5rem;
	display: block;
	border-top: 5px dashed $color-one;
	margin: -2.5rem auto 2.5rem;

	@media (min-width: $dotted-line-break-one) {
		margin: -4.5rem auto 4.5rem;
	}
}

