
$offer-banner-break-one: 1000px;

.offer-banner {
	background-color: $color-seven;
	border-bottom: 3px solid $color-one;
	margin-top: -5rem;
	margin-bottom: 5rem;

	&--text {
		background-color: $color-one;
		padding: 1rem;
		margin-top: -7rem;

		.offer-banner__link {
			padding: 0;
		}
	}

	&__container {}

	&__link {
		text-decoration: none;
		color: $color-three;
		text-align: center;
		padding: 165px 0 1.3rem;
		display: block;

		@media (min-width: $offer-banner-break-one) {
			padding: 0.8rem 190px 1.3rem;
		}
	}

	&__icon {
		position: absolute;
		max-width: 130px;
		top: 25px;
		left: 50%;
		transform: translateX(-50%);
		
		@media (min-width: $offer-banner-break-one) {
			max-width: 185px;
			top: unset;
			bottom: 0;
			left: 0;
			top: unset;
			transform: unset;
		}
		
	}

	&__heading {
		@include font-preset--2;
		font-weight: 800;
		line-height: 1.2;
		margin: 0 0 0.5rem;
		max-width: unset;
	}

	&__copy {
		@include font-preset--3;
		font-weight: 400;
		margin: 0;
		max-width: unset;
	}

	&__line {
		@include font-preset--3;
		margin: 0;
		font-weight: 400;
		text-transform: none;
	}

	span {
		font-weight: 800;
	}
}
