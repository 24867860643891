.team-listing {
	&__container {}

	&__list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin-right: -1.8rem !important;
	}

	&__item {
		max-width: unset;
		width: calc(100% - 1.8rem);
		margin: 0 1.8rem 1.8rem 0;

		@media (min-width: 600px) {
			width: calc(50% - 1.8rem);
		}

		@media (min-width: 1000px) {
			width: calc(33.33% - 1.8rem);
		}
	}

	&__image {}

	&__heading {
		@include font-preset--6;
	}

	&__strapline {
		margin-top: 0.5ex;
	}

	&__copy {
		margin-top: 1ex;
		padding-right: 1rem;
	}
}

