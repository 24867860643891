$alternating-break-one: 950px;
$alternating-break-two: 1300px;

.alternating {
	margin-bottom: 5rem;


	h1 {
		@include font-preset--2;
		margin: 0 0 2rem;
	}

	&--padded {
		padding: 3rem 0;

		+.alternating--padded {
			margin-top: -5rem;

			@media (min-width: $spacer-break-one) {
				margin-top: -8rem;
			}
		}
	}

	&.background--one {
		p {
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
	}

	&--editorial {
		.alternating__container {
			align-items: unset;
		}

		.alternating__text {
			padding: 2rem 0;

			@media (min-width: $spacer-break-one) {
				padding: 4rem 0;
				min-height: 45vh;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		.alternating__image {
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;
			width: 100%;
			padding-top: 50%;
			margin-top: 0;
			margin-bottom: 3rem;

			@media (min-width: $spacer-break-one) {
				padding-top: unset;
				margin-right: -4.3rem;
				width: calc(50% + 4.3rem);
				margin-bottom: unset;
			}

			img {
				height: 0;
				overflow: hidden;
				opacity: 0;
			}
		}

		@media (min-width: $spacer-break-one) {
			&.alternating--flip {
				.alternating__image {
					margin-left: -4.3rem;
				}
			}
		}
		
		+.alternating--editorial {
			margin-top: -5rem;

			@media (min-width: $spacer-break-one) {
				margin-top: -8rem;
			}
		}
	}

	&__text-icon {
		display: none;
	}

	&--grid {
		.alternating__container {
			@media (min-width: $spacer-break-one) {
				min-height: 70vh;
			}
		}

		.alternating__overline {
			@include font-preset--3;
		}

		.alternating__image {
			border: 7px solid #fff;

			@media (min-width: $spacer-break-one) {
				border: unset;
				border-left: 10px solid #fff;
			}
		}

		.alternating__text-icon {
			@media (min-width: $alternating-break-two) {
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 18%;
				max-width: 280px;
			}
		}

		.alternating__text {
			@media (min-width: $alternating-break-two) {
				padding-left: 15%;
			}
		}
	}

	@media (min-width: $spacer-break-one) {
		margin-bottom: 8rem;
	}

	@media (min-width: $alternating-break-one) {
		&--flip {
			.alternating__container {
				flex-direction: row-reverse;
			}

			.alternating__text {
				@media (min-width: 1400px) {
					padding-left: unset;
					padding-right: 5rem;
				}
			}

			&.alternating--wide {
				.alternating__image {
					width: calc(50% + 4.3rem);
					margin-left: -4.3rem;
				}
			}
		}

		&--wide {
			margin-bottom: 0;
			z-index: 1;

			.alternating__image {
				width: calc(50% + 4.3rem);
				margin-right: -4.3rem;
			}
		}
	}

	&.background {
		padding: 4rem 0;

		@media (min-width: $alternating-break-one) {
			padding: 1.5rem 0 0;

			.alternating__image {
				margin-bottom: -1.5rem;
			}
		}
	}

	&__highlight {
		color: $color-six;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		@media (min-width: 1200px) {
			align-items: center;
		}
	}

	&__text {
		width: 100%;

		@media (min-width: $alternating-break-one) {
			width: calc(50% - 4.5rem);
		}

		@media (min-width: 1400px) {
			width: calc(50% - 7.5rem);
			padding-left: 5rem;
		}
	}

	&__heading {
		@include font-preset--3;
		margin: 0;

		+.alternating__copy {
			margin-top: 0.5rem;
		}
	}

	&__copy {
		@include font-preset--8;

		strong {
			font-weight: 600;
		}
	}

	&__image {
		margin-top: 3rem;

		@media (min-width: $alternating-break-one) {
			margin-top: 0;
			width: 50%;
		}
		
	}

	&__logo {
		max-width: 18rem;
	}

	.button {
		margin: 2rem 0;
	}
}

