.copy-hero {
	text-align: center;
	margin-bottom: 0;

	&__container {
		max-width: 1000px;
	}

	&__heading {
		@include font-preset--2;
		margin: 0 0 0.7ex;
		max-width: unset;
	}

	.dotted-line {
		margin: 0 auto;
	}

	&__subheading {
		@include font-preset--4;
		margin-top: 1.5ex;
	}

	&__copy {
		@include font-preset--9;
		margin: 2ex auto 0;
	}
}

