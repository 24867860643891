.articles {
	background-color: $color-two;
	padding: 4.5rem 0 3rem;

	&__section-heading {
		@include font-preset--3;
		margin: 0 0 1.3ex;
	}

	&__container {}
	
	&__cols {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1.8rem !important;
	}

	&__item {
		width: calc(100% - 1.8rem);
		margin: 0 1.8rem 1.8rem 0;

		@media (min-width: 520px) {
			width: calc(50% - 1.8rem);
		}

		@media (min-width: 800px) {
			width: calc(33.33% - 1.8rem);
		}
	}

	&__image {
		padding-bottom: 75%;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
	}

	&__heading {
		@include font-preset--6;
		width: calc(100% - 1.8rem);
	}

	&__copy {
		@include font-preset--9;
		color: $color-four;
	}
}