$col-hero-break-one: 1000px;

.col-hero {
	margin-bottom: 5rem;

	@media (min-width: $spacer-break-one) {
		margin-bottom: 8rem;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	&__image {
		width: 100%;
		max-width: 300px;
		margin: 0 auto 2rem;

		@media (min-width: $col-hero-break-one) {
			max-width: unset;
			margin: 0;
			width: 50%;
		}
	}

	&__text {
		padding: 0 2rem;
		text-align: center;
		width: 100%;

		@media (min-width: $col-hero-break-one) {
			width: 50%;
		}
		
		@media (min-width: 1300px) {
			padding: 0 5rem;
		}
	}

	&__heading {
		@include font-preset--2;
		margin: 0 0 0.2ex;
	}

	.dotted-line {
		margin: 0 auto;
	}

	&__intro {
		@include font-preset--7;
		margin-top: 3.5ex;

		@media (max-width: 1600px) {
			font-size: 1.2rem;
		}
	}

	&__copy {
		@include font-preset--8;

		@media (max-width: 1600px) {
			font-size: 1rem;
		}
	}
}