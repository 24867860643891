.header {
	z-index: 10;
	padding: 4rem 0;
	margin-bottom: 3rem;

	&--overlay {
		margin-bottom: 0;

		.nav {
			color: #fff !important;
		}

		.header__logo {
			display: none;

			&--white {
				display: block;
			}
		}

		.nav-toggle {
			background-image: url('/assets/img/icon-nav-open-white.svg');

			&--active {
				background-image: url('/assets/img/icon-nav-close-white.svg');
			}
		}
	}

	.nav {
		color: $color-one;
	}

	&__container {}

	&__logo {
		z-index: 1;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50%;
		max-width: 14rem;
		transform: translate(-50%, -50%);

		&--white {
			display: none;
		}
		
		@media (min-width: 1000px) {
			max-width: 20rem;
		}

		@media (min-width: 1200px) {
			max-width: 28rem;
		}
	}
}