.overlay-hero {
	text-align: center;
	color: $color-three;
	background-color: $color-one;
	min-height: 80vh;
	padding: 4rem 0 8rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 5rem;

	+.alternating {
		@media (min-width: 1550px) {
			margin-top: -6.5rem;
		}
	}

	&__container {
		z-index: 2;
	}

	&__heading {
		@include font-preset--1;
		margin: 0;
	}

	&__subheading {
		@include font-preset--4;
		margin-top: 1ex;
	}

	&__copy {
		@include font-preset--7;
		margin-top: 2.3ex;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		mix-blend-mode: soft-light;
		opacity: 0.4;
	}

	&__badge {
		width: 11.5rem;
		display: block;
		position: absolute;
		z-index: 100;
		top: 3rem;
		right: -6rem;
		transform: rotate(10deg);

		@media (max-width: 1420px) {
			width: 11rem;
			right: 1rem;
		}

		@media (max-width: 1130px) {
			+h1 {
				padding: 0 8rem;
			}
		}

		@media (max-width: 1000px) {
			display: none;

			+h1 {
				padding: unset;
			}
		}
	}
}


