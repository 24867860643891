$contact-break-one: 900px;

.contact {
	background-color: $color-two;
	margin-bottom: 0;

	&__container {
		@media (min-width: $contact-break-one) {
			display: flex;
			justify-content: flex-end;
		}
	}

	&__map {
		background-color: $color-one;
		background-image: url('/assets/img/contact-map.png');
		background-position: 50%;
		background-size: cover;
		aspect-ratio: 1/0.6;
		margin-left: -4.5rem;
		margin-right: -4.5rem;

		@media (min-width: $contact-break-one) {
			aspect-ratio: unset;
			margin-left: unset;
			margin-right: unset;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 50%;
		}
	}

	&__panel {
		width: 100%;
		padding: 3.5rem 0;

		@media (min-width: $contact-break-one) {
			padding: 3.5rem;
			width: 50%;
		}
	}

	&__heading {
		@include font-preset--3;
		margin: 0;
	}

	&__copy {
		@include font-preset--9;
	}

	.icon-list {
		a {
			@media (max-width: 1300px) {
				font-size: 1.2rem;
			}
		}
	}
}
