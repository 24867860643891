// 900
// 700
// 400
// 300

$font-break-one: 1500px; // x / 1.3
$font-break-two: 700px; // x / 1.6

@mixin font-preset--1 {
	font-size: 2.8rem;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1;

	@media (min-width:$font-break-two) {
		font-size: 4.6rem;
	}

	@media (min-width:$font-break-one) {
		font-size: 6rem;
	}
}

@mixin font-preset--2 {
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width:$font-break-two) {
		font-size: 3.3rem;
	}

	@media (min-width:$font-break-one) {
		font-size: 4.3rem;
	}
}

@mixin font-preset--3 {
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width:$font-break-one) {
		font-size: 1.9rem;
	}
	
}

@mixin font-preset--4 {
	font-size: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width:$font-break-two) {
		font-size: 2rem;
	}
}

@mixin font-preset--5 {
	font-size: 1.3rem;
	font-weight: 900;
	text-transform: uppercase;

	@media (min-width:$font-break-two) {
		font-size: 1.8rem;
	}
}

@mixin font-preset--6 {
	font-size: 1rem;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width:$font-break-two) {
		font-size: 1.25rem;
	}
}

@mixin font-preset--7 {
	font-size: 1.3rem;
	font-weight: 400;

	@media (min-width:$font-break-two) {
		font-size: 1.8rem;
	}
}

@mixin font-preset--8 {
	font-size: 1.1rem;
	font-weight: 400;

	@media (min-width:$font-break-two) {
		font-size: 1.5rem;
	}
}

@mixin font-preset--9 {
	font-size: 1rem;
	font-weight: 400;

	@media (min-width:$font-break-two) {
		font-size: 1.25rem;
	}
}

@mixin font-preset--10 {
	font-size: 0.87rem;
	font-weight: 400;

	@media (min-width:$font-break-two) {
		font-size: 1.12rem;
	}
}


h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	@include font-preset--10;
	margin: 2.6ex 0 0;
	max-width: 80ch;
	line-height: 1.35;

	a {
		color: $color-four;
		text-decoration: none;
	}
}

p {
	strong {
		font-weight: 600;
	}

	a {
		color: $color-one;
	}
}

.font-preset--1,
h1 {
	@include font-preset--1;
}

.font-preset--2,
h2 {
	@include font-preset--2;
}

.font-preset--3,
h3 {
	@include font-preset--3;
}

.font-preset--4, 
h4 {
	@include font-preset--4;
}

.font-preset--5,
h5 {
	@include font-preset--5;
}

.font-preset--6,
h6 {
	@include font-preset--6;
}

.font-preset--7 {
	@include font-preset--7;
}

.font-preset--8 {
	@include font-preset--8;
}

.font-preset--9 {
	@include font-preset--9;
}

.font-preset--10 {
	@include font-preset--10;
}
