$nav-break: 1000px;

.nav-toggle {
	display: none;

	@media (max-width: $nav-break) {
		display: block;
		background-image: url('/assets/img/icon-nav-open-green.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: transparent;
		border: 0;
		background-size: 1.5rem;
		appearance: none;
		width: 30px;
		height: 30px;
		z-index: 2;

		&--active {
			background-image: url('/assets/img/icon-nav-close-white.svg');
		}
	}
}

.nav {
	background-color: #707070;
	position: fixed;
	width: 300px;
	padding: 7rem 2rem 3rem;
	left: 0;
	top: 0;
	z-index: 1;
	transform: translateX(-100%);
	opacity: 0;
	transition: transform 0.2s, opacity 0.2s;
	
	&--active {
		transform: translateX(0);
		opacity: 1;
	}

	@media (min-width: 700px) {
		padding: 7rem 4.3rem 4rem;
		width: 400px;
	}

	@media (min-width: $nav-break) {
		opacity: unset;
		transform: unset;
		background-color: unset;
		position: relative;
		width: unset;
		padding: unset;
		left: unset;
		top: unset;
		z-index: unset;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	color: $color-one;

	&__list {
		margin: 0;
		color: inherit;

		
		.nav__item {
			@media (min-width: $nav-break) {
				margin: 0 1rem 0 0;
			}
		}


		@media (min-width: $nav-break) {
			&:last-of-type {
				.nav__item {
					margin: 0 0 0 1rem;
				}
			}
		}

	}

	&__item {
		color: inherit;

		@media (min-width: 1000px) {
			&--mob-hidden {
				display: none !important;
			}
		}

		a {
			@include font-preset--6;
			color: inherit;

			@media (max-width: $nav-break) {
				color: #fff;
			}
		}

		@media (min-width: $nav-break) {
			display: inline-block;
		}
	}
}




