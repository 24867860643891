$scrolling-break-one: 680px;

.scrolling {

	+ .offer-banner {
		margin-top: 2rem;

		@media (min-width: $scrolling-break-one) {
			position: sticky;
			bottom: 0;
		}
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__image {
		width: 100%;

		@media (min-width: $scrolling-break-one) {
			width: calc(50% - 0.9rem);
			position: sticky;
			top: 1rem;
		}
	}

	&__text {
		width: 100%;

		@media (min-width: $scrolling-break-one) {
			width: calc(50% - 0.9rem);
		}
	}

	&__overline {
		font-size: 0.95rem;
		font-weight: 600;
	}

	&__heading {
		@include font-preset--2;
		margin: 0;
	}

	.dotted-line {
		margin: 1rem 0 0 0.2rem;
	}

	&__text-block {
		h2,
		h3,
		h4 {
			@include font-preset--6;
		}

		p {
			margin-top: 0.6ex;
		}
	}
}
